import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

const ThemesPage = ({ data, location }) => {
  const title = 'Matomo themes';
  return (
    <Layout location={location} title={title}>
      <h1>
        {`Themes (${data.themes.nodes.length + data.themesOld.nodes.length}`})
      </h1>
      {data?.themes?.nodes && Table({ data: data.themes.nodes, owner: true })}
      <h2>
        {`${data.themesOld.nodes.length} themes (not updated since 2022)`}
      </h2>

      {data?.themesOld?.nodes &&
        Table({ data: data.themesOld.nodes, owner: true })}
    </Layout>
  );
};

export const query = graphql`
  {
    themes: allMatomoTheme(
      sort: { order: DESC, fields: lastUpdated }
      filter: { lastUpdated: { gte: "2023-01-01" } }
    ) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        repositoryUrl
        owner
      }
    }
    themesOld: allMatomoTheme(
      sort: { order: DESC, fields: lastUpdated }
      filter: { lastUpdated: { lt: "2023-01-01" } }
    ) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        repositoryUrl
        owner
      }
    }
  }
`;

export default ThemesPage;
